import { ReactFragment } from 'react';

import useTheme from 'modules/Theme/hooks/useTheme';
import { Box } from 'modules/Ui';

interface TagProps {
  children: ReactFragment;
  'data-testid'?: string;
  menuIsOpen?: boolean;
}

const PanelList = (props: TagProps) => {
  const theme = useTheme();
  const { menuIsOpen, children } = props;
  return (
    <Box
      aria-hidden={menuIsOpen}
      backgroundColor="gray0"
      borderTop={{ _: `1px solid ${theme.colors.primary100}`, sm: 'initial' }}
      border={{ sm: `1px solid ${theme.colors.primary100}` }}
      data-testid={props['data-testid']}
      paddingBottom="40px"
      tag="section"
    >
      {children}
    </Box>
  );
};

export default PanelList;
