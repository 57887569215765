import { useSelector } from 'react-redux';

import { selectHasExternalPlan } from 'modules/Subscriptions/detail/selectors';
import { useTenant } from 'modules/Subscriptions/hooks';
import { Tenants } from 'modules/Subscriptions/models';

function useIsAutonomsTelefonica() {
  const tenant = useTenant();
  const isSubscriptionExternal = useSelector(selectHasExternalPlan);
  return tenant === Tenants.AUTONOMOS_TELEFONICA && isSubscriptionExternal;
}

export default useIsAutonomsTelefonica;
