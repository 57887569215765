import { FC } from 'react';

import { space, SpaceProps, border, BorderProps } from 'styled-system';

import styled from 'modules/Theme/styled-components';

import Box from '../Box';
import IconSearch from '../Icons/IconSearch';
import Text from '../Text';

interface ZeroResultsProps extends SpaceProps, BorderProps {
  subtitle?: string;
  title: string;
}

export const StyledWrapper = styled(Box)`
  ${space}
  ${border}
`;

export const ZeroResults: FC<ZeroResultsProps> = ({
  title,
  subtitle,
  ...rest
}) => {
  return (
    <StyledWrapper fadeIn textAlign="center" {...rest}>
      <IconSearch size={31} color="gray600" />
      <Text color="primary500" fontSize={14} lineHeight={22} margin="8px 0">
        {title}
      </Text>
      <Text textSize="xs">{subtitle}</Text>
    </StyledWrapper>
  );
};

StyledWrapper.defaultProps = {
  margin: { sm: '0 16px', md: '0 24px', lg: '0 32px' },
  paddingTop: { _: '40px', sm: '88px' },
};
