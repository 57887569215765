import accessibleHidden from 'modules/Theme/mixins/accessibleHidden';
import styled from 'modules/Theme/styled-components';

import Input from '../../Html/Input';
import Check from './Check';
import LabelText from './LabelText';
import Wrapper from './Wrapper';

const CheckboxInput = styled(Input)`
  ${accessibleHidden}

  &:focus {
    & + ${Wrapper} {
      ${Check} {
        --check-border-color: ${(props) => props.theme.colors.brand500};
      }
    }
  }

  &:hover {
    & + ${Wrapper} {
      ${Check} {
        --check-border-color: ${(props) => props.theme.colors.brand500};
      }
      ${LabelText} {
        --check-label-color: ${(props) => props.theme.colors.gray800};
      }
    }
  }

  &:checked {
    + ${Wrapper} {
      ${Check} {
        --check-bg-color: ${(props) => props.theme.colors.brand500};
        &:after {
          opacity: 1;
        }
      }
      ${LabelText} {
        --check-label-color: ${(props) => props.theme.colors.gray800};
      }
    }
    @media(hover : hover) {
      &:hover + ${Wrapper} ${Check} {
        --check-bg-color: ${(props) => props.theme.colors.brand400};
        --check-border-color: ${(props) => props.theme.colors.brand400};
      }
    }

    &:disabled {
      + ${Wrapper} {
        ${Check} {
          --check-bg-color: ${(props) => props.theme.colors.gray300};
        }
      }

      &:hover {
        & + ${Wrapper} {
          ${Check} {
            --check-border-color: ${(props) => props.theme.colors.gray300};
          }
        }
      }
    }
  }

  &:disabled + ${Wrapper} {
    cursor: text;
    --check-bg-color: ${(props) => props.theme.colors.gray200};
    ${Check} {
      --check-border-color: ${(props) => props.theme.colors.gray300};
      &:after {
        opacity: 1;
        will-change: transition;
      }
    }
    ${LabelText} {
      --check-label-color: ${(props) => props.theme.colors.gray400};
    }
  }

  &:disabled:not(:checked) + ${Wrapper} {
    ${Check} {
      &:after {
        opacity: 0;
      }
    }
  }
`;

export default CheckboxInput;
