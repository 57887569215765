import { css } from 'modules/Theme/styled-components';

export interface HoverEffectProps {
  $active?: boolean;
}

const hoverEffect = css`
  opacity: 0.1;
  z-index: 1;
  will-change: opacity;
`;

const hoverAnimation = css<HoverEffectProps>`
  &:after {
    background-color: ${(props) => props.theme.colors.primary300};
    content: "";
    display: block;
    height: 100%;
    left: 0;
    opacity: ${({ $active }) => ($active ? '0.1' : '0')};
    position: absolute;
    top: 0;
    transition: opacity 0.4s ease-in;
    width: 100%;
  }

  @media(hover : hover) {
    &:hover {
      &:after {
        ${hoverEffect}
      }
    }
  }

  &:focus {
    &:after {
      ${hoverEffect}
    }
  }
`;

export default hoverAnimation;
