import { FC } from 'react';

import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  system,
} from 'styled-system';

import { fadeInAnimation } from 'modules/Theme/mixins/fadeIn';
import styled from 'modules/Theme/styled-components';
import { Box } from 'modules/Ui';

import { TextRenderer, TextRendererProps } from './TextRenderer';

interface WarningBaseProps
  extends TextRendererProps,
    SpaceProps,
    LayoutProps,
    FlexboxProps {
  boxSizing?: 'content-box' | 'border-box';
  backgroundColor: string;
  fadeIn?: boolean;
  gridColumnEnd?: {} | string;
  gridColumnStart?: {} | string;
  icon: JSX.Element;
  iconSize: number;
  messageFontSize?: number;
  messageLineHeight?: number;
  messageMargin?: string;
}

const StyledWarningBase = styled(Box)`
  ${({ fadeIn }) => fadeIn && fadeInAnimation}
  ${space}
  ${flexbox}
  ${layout}
  ${system({
    gridColumnStart: {
      property: 'gridColumnStart',
      transform: (value) => `${value}`,
    },
    gridColumnEnd: {
      property: 'gridColumnEnd',
      transform: (value) => `${value}`,
    },
    boxSizing: {
      property: 'boxSizing',
      scale: 'boxSizings',
    },
  })}
`;

const WarningBase: FC<WarningBaseProps> = ({
  backgroundColor,
  boxSizing,
  icon,
  iconSize,
  message,
  messageFontSize,
  messageLineHeight,
  messageMargin,
  useMarkdown,
  children,
  ...rest
}) => {
  return (
    <StyledWarningBase
      alignItems="center"
      backgroundColor={backgroundColor}
      boxSizing={boxSizing}
      columnGap="10px"
      display="grid"
      gridTemplateColumns={`${iconSize}px 1fr`}
      padding="16px"
      role="alert"
      {...rest}
    >
      {icon}
      {children || (
        <TextRenderer
          message={message}
          messageFontSize={messageFontSize}
          messageLineHeight={messageLineHeight}
          messageMargin={messageMargin}
          useMarkdown={useMarkdown}
        />
      )}
    </StyledWarningBase>
  );
};

export default WarningBase;
