import { useCallback, useEffect, useRef, useState } from 'react';
import { Hidden, Visible } from 'react-grid-system';

import { isEqual } from 'lodash';

import styled from 'modules/Theme/styled-components';
import Limits from 'types/limits';

import Box from '../Box';
import { Pagination } from '../Pagination';
import Spinner from '../Spinner';
import { ZeroResults } from '../ZeroResults';
import {
  DataTableAction,
  DataTableColumn,
  DataTableSort,
} from './DataTable.models';
import DataTableHeader from './DataTableHeader';
import DataTableItem from './DataTableItem';
import DataTableSelectedActions from './DataTableSelectedActions';

interface DataTableProps<T extends object, U extends keyof T> {
  allowSelectAll?: boolean;
  className?: string;
  noGutters?: boolean;
  data: T[];
  keyName: U;
  columns: DataTableColumn<T, U>[];
  actions: DataTableAction<T>[];
  page: number;
  pageSize: Limits;
  totalCount: number;
  multiple?: boolean;
  sortBy?: DataTableSort<T, U>;
  loading?: boolean;
  emptyDataTitle?: string;
  emptyDataSubTitle?: string;
  // we just need to change reference to trigger selection reset
  filters?: unknown;
  ['data-testid']?: string;
  onSelect?: (data: T) => void;
  onInvertedChange?: (inverted: boolean) => void;
  onSelectionChange?: (newSelected: T[]) => void;
  onPageChange?: (page: number) => any;
  onPageSizeChange?: (pageSize: Limits) => any;
  onSortChange?: (sortBy?: DataTableSort<T, U>) => any;
  isReader?: boolean;
  hideCheck?: boolean;
  showEmptySpace?: boolean;
  initialSelection?: T[];
}

const getShowCheck = (
  isReader?: boolean,
  multiple?: boolean,
  hideCheck?: boolean
) => {
  return !hideCheck && multiple && !isReader;
};

export function DataTableBase<T extends object, U extends keyof T>(
  props: DataTableProps<T, U>
) {
  const {
    allowSelectAll,
    className,
    noGutters = false,
    data = [],
    keyName,
    columns,
    actions,
    page,
    pageSize,
    totalCount,
    multiple,
    sortBy,
    loading,
    emptyDataTitle = '',
    emptyDataSubTitle = '',
    filters,
    onInvertedChange,
    onSelect,
    onSelectionChange,
    onPageSizeChange,
    onPageChange,
    onSortChange,
    isReader = false,
    hideCheck = false,
    showEmptySpace,
    initialSelection,
  } = props;
  const [selected, setSelected] = useState<T[]>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [anySelected, setAnySelected] = useState(false);
  const [inverted, setInverted] = useState(false);
  const filtersRef = useRef(filters);
  if (!isEqual(filtersRef.current, filters)) {
    filtersRef.current = filters;
  }

  const resetSelection = useCallback(() => {
    setSelected([]);
    setInverted(false);
    setAllSelected(false);
    setAnySelected(false);
    if (onSelectionChange) {
      onSelectionChange([]);
    }
  }, [onSelectionChange]);

  function handleItemCheck(item: T) {
    let newSelected = selected;
    const alreadySelected = selected.find((i) => item[keyName] === i[keyName]);

    if (alreadySelected) {
      newSelected = selected.filter((i) => i[keyName] !== item[keyName]);
    } else {
      const checkedItem = data.find((i) => item[keyName] === i[keyName]);
      if (checkedItem) {
        newSelected = selected.concat([checkedItem]);
      }
    }

    setSelected(newSelected);
    setAllSelected(
      hasAllSelected(
        data.map((i) => i[keyName]),
        newSelected.map((i) => i[keyName])
      )
    );
    setAnySelected(
      hasAnySelected(
        data.map((i) => i[keyName]),
        newSelected.map((i) => i[keyName])
      )
    );
    if (onSelectionChange) {
      onSelectionChange(newSelected);
    }
  }

  function hasAllSelected(source: T[U][], test: T[U][]) {
    return source.every((id) => test.includes(id));
  }

  function hasAnySelected(source: T[U][], test: T[U][]) {
    return source.some((id) => test.includes(id));
  }

  function isSelected(item: T, source: T[]) {
    return !!source.find((i) => i[keyName] === item[keyName]);
  }

  function handleSelectAll() {
    const sourceIds = data.map((item) => item[keyName]);
    let newSelected = selected;
    if (inverted) {
      resetSelection();
      return;
    }

    if (allSelected) {
      newSelected = selected.filter(
        (selectedItem) => !sourceIds.includes(selectedItem[keyName])
      );
      setAllSelected(false);
      setAnySelected(false);
    } else {
      data.forEach((row) => {
        if (
          !newSelected.find(
            (selectedItem) => selectedItem[keyName] === row[keyName]
          )
        ) {
          newSelected.push(row);
        }
      });
      setAllSelected(true);
      setAnySelected(true);
    }
    if (onSelectionChange) {
      onSelectionChange(newSelected);
    }
    setSelected(newSelected);
  }

  function handleToggleInverted() {
    resetSelection();
    setInverted(!inverted);
    if (onInvertedChange) {
      onInvertedChange(!inverted);
    }
  }

  function handleClickAction(d: T[], i: boolean, action: DataTableAction<T>) {
    action.onClick(d, i, resetSelection, totalCount);
  }

  useEffect(() => {
    setAllSelected(
      hasAllSelected(
        data.map((i) => i[keyName]),
        selected.map((i) => i[keyName])
      )
    );
    setAnySelected(
      hasAnySelected(
        data.map((i) => i[keyName]),
        selected.map((i) => i[keyName])
      )
    );
  }, [data]);

  useEffect(() => {
    resetSelection();
  }, [filtersRef.current, resetSelection]);

  useEffect(() => {
    setSelected(initialSelection || []);
    onSelectionChange?.(initialSelection || []);
  }, [initialSelection, onSelectionChange]);

  if (!data.length && !loading) {
    return (
      <Box minHeight="400px">
        <ZeroResults subtitle={emptyDataSubTitle} title={emptyDataTitle} />
      </Box>
    );
  }
  const gutters = noGutters
    ? {}
    : { minHeight: 450, paddingTop: { sm: '18px', md: '26px' } };

  const showCheck = getShowCheck(isReader, multiple, hideCheck);

  return (
    <Box
      data-testid={props['data-testid']}
      display={{ sm: 'flex' }}
      flexDirection={{ sm: 'column' }}
      justifyContent={{ sm: 'space-between' }}
      position={{ sm: 'relative' }}
      {...gutters}
    >
      <Box
        data-testid="list-items"
        className={className}
        position="relative"
        tag="table"
        width="100%"
      >
        <Visible md lg xl xxl>
          <DataTableHeader<T, U>
            columns={columns}
            withCheck={showCheck}
            checked={inverted ? !anySelected : allSelected}
            sortBy={sortBy}
            onCheck={handleSelectAll}
            onSortChange={(payload) => {
              resetSelection();
              onSortChange && onSortChange(payload);
            }}
            showEmptySpace={showEmptySpace}
          />
        </Visible>
        <Box tag="tbody" contentVisibility={{ sm: 'auto' }}>
          {data &&
            data.map((item, index) => (
              <DataTableItem<T, U>
                key={`${item[keyName]}`}
                keyName={keyName}
                data={item}
                index={index}
                columns={columns}
                withCheck={showCheck}
                showEmptySpace={showEmptySpace}
                onClick={onSelect}
                checked={
                  inverted
                    ? !isSelected(item, selected)
                    : isSelected(item, selected)
                }
                onCheck={handleItemCheck}
              />
            ))}
        </Box>
      </Box>
      {loading && <Spinner color="brand500" backdropColor="backdropLight" />}
      {totalCount > pageSize && (
        <Box margin={{ sm: '0 32px' }}>
          <Pagination
            allowSelectAll={allowSelectAll}
            selected={selected}
            totalCount={totalCount}
            offset={page * pageSize}
            limit={pageSize}
            count={totalCount}
            selectionInverted={inverted}
            changeLimit={(p) => onPageSizeChange && onPageSizeChange(+p)}
            changeOffset={(p) => onPageChange && onPageChange(+p / pageSize)}
            onToggleInvertedSelection={handleToggleInverted}
            showEmptySpace={showEmptySpace}
          />
        </Box>
      )}
      {selected.length || inverted ? (
        <Hidden xs>
          <DataTableSelectedActions<T>
            actions={actions}
            selected={selected}
            selectionInverted={inverted}
            onClickAction={handleClickAction}
          />
        </Hidden>
      ) : (
        ''
      )}
    </Box>
  );
}

const DataTable = styled(DataTableBase)`
  font-size: ${(props) => props.theme.fontSizes[14]};
`;

export default DataTable as typeof DataTableBase;
