import { useSelector } from 'react-redux';

import { selectCurrentBusiness } from 'modules/Business/Current/selectors';

import { RenewableCampaignCodes } from '../detail/constants';

function useIsExternalRenewablePlan(): boolean {
  const business = useSelector(selectCurrentBusiness);

  if (business.campaign) {
    return RenewableCampaignCodes.includes(business.campaign?.code);
  }

  return false;
}

export default useIsExternalRenewablePlan;
