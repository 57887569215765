import { FC, useMemo, useState } from 'react';
import { Visible } from 'react-grid-system';
import { useDispatch } from 'react-redux';

import useProductImpression from 'modules/Analytics/hooks/useProductImpression';
import analyticsService from 'modules/Analytics/services/analyticsService';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { PAYMENT_PERIODS, PLANS, Plan } from 'modules/Subscriptions/models';
import { getPeriodicity } from 'modules/Subscriptions/subscription.helpers';
import { Box, MarkDown } from 'modules/Ui';
import { setIsBodyScrollable } from 'modules/Ui/store/actions';

import messages from '../../../messages';
import PlanPeriodicity from './PlanPeriodicity';
import PlansHeading from './PlansHeading';
import PlansIlustations from './PlansIlustations';
import PlansInfo from './PlansInfo';
import PlanWrapper from './PlanWrapper';

const { MONTHLY, YEARLY } = PAYMENT_PERIODS;
interface Props {
  onChangeBusinessClick(): void;
  onSelectPlan(data: Plan): void;
  plans: Plan[];
  activePlan?: string;
}

function getPlans(plans: Plan[], periodicity: string) {
  const plansFilteredByPeriodicity = plans.filter((plan) => {
    const planPeriodicity = getPeriodicity(plan.duration);
    return planPeriodicity === periodicity;
  });
  const sortedPlans = plansFilteredByPeriodicity.sort(
    (a, b) => a.order - b.order
  );
  return sortedPlans;
}

function isPlanActive(planName: string, activePlanName?: string) {
  return planName === activePlanName;
}

const SubscriptionsPlans: FC<Props> = ({
  onChangeBusinessClick,
  onSelectPlan,
  plans,
  activePlan,
}) => {
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const [periodicity, setPeriodicity] = useState(YEARLY);
  const [hoveredPlan, setHoveredPlan] = useState('');
  const filteredPlans = useMemo(
    () => getPlans(plans, periodicity),
    [plans, periodicity]
  );
  function onPlanHover(planProductId: string) {
    setHoveredPlan(planProductId);
  }

  useProductImpression(filteredPlans);
  const onlyOnePlan = filteredPlans.length === 1;

  dispatch(setIsBodyScrollable(true));

  return (
    <Box textAlign={{ sm: 'center' }} padding={{ _: '0 16px', sm: 'initial' }}>
      <PlansHeading {...{ onChangeBusinessClick }} />
      <Visible md lg xl xxl>
        <PlansIlustations {...{ activePlan: hoveredPlan }} />
      </Visible>
      <PlanPeriodicity
        periodicity={`${periodicity}`}
        onChange={(ev: any) => {
          const isAnnualChecked = ev.target.checked;
          const periodValue = isAnnualChecked ? YEARLY : MONTHLY;
          setPeriodicity(periodValue);
        }}
        centered={onlyOnePlan}
        {...{ plans }}
      />

      <Box
        display={{ sm: 'grid' }}
        gap={{ sm: '16px', md: '24px' }}
        gridTemplateColumns={{
          sm: `repeat(${filteredPlans.length}, 1fr);`,
        }}
        justifyContent={onlyOnePlan ? 'center' : undefined}
        marginBottom={{ sm: '16px' }}
        tag="section"
      >
        {filteredPlans.map((plan) => {
          const { productId, marketName, price, campaign } = plan;
          const planIsPro = PLANS.PRO.includes(plan.productId);
          const campaignPopularPlan = !!campaign && onlyOnePlan;
          const planType = planIsPro ? 'popular' : 'basic';
          return (
            <PlanWrapper
              key={productId}
              onSelectPlan={() => {
                analyticsService.productClick(plan);
                onSelectPlan(plan);
              }}
              isActive={isPlanActive(plan.marketName, activePlan)}
              onMouseEnter={() => onPlanHover(productId)}
              campaign={campaign}
              campaignPopularPlan={campaignPopularPlan}
              periodicity={periodicity}
              planName={marketName}
              planType={planType}
              productId={productId}
              price={price}
            >
              <PlansInfo productId={productId} />
            </PlanWrapper>
          );
        })}
      </Box>
      <Box
        fontSize={{ _: 16, md: 20 }}
        lineHeight={22}
        marginBottom={{
          _: '40px',
          md: '61px',
          lg: '48px',
        }}
        paddingTop={{ _: '15px', sm: '8px', md: '16px' }}
      >
        <MarkDown text={t(messages.plansSelect)} />
      </Box>
    </Box>
  );
};

export default SubscriptionsPlans;
