import React, { ReactNode, FC } from 'react';

import {
  background,
  border,
  space,
  layout,
  flexbox,
  typography,
  system,
} from 'styled-system';

import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';

import { fadeInAnimation } from '../../../Theme/mixins/fadeIn';
import Box, { BoxProps } from '../../Box/Box';
import Text from '../../Text';

export interface FieldSetAction {
  title: string;
  icon?: React.Component | JSX.Element;
}
interface FieldsetProps extends BoxProps {
  children: string | ReactNode;
  contentVisibility?: 'visible' | 'hidden' | 'auto' | '';
  fadeIn?: boolean;
  hiddenLegend?: boolean;
  id: string;
  legend: string | ReactNode;
  legendFontSize?: string | number;
  legendFontWeight?: string;
  legendGridArea?: string | {};
  legendGridColumnEnd?: {} | string;
  legendGridColumnStart?: {} | string;
  legendMarginBottom?: string | {};
  styledLike?: 'subheading' | 'label';
  uppercase?: boolean;
}

const FieldsetWrapper = styled(Box)`
  ${background}
  ${space}
  ${layout}
  ${flexbox}
  ${border}
  ${typography}
  ${({ fadeIn }) => fadeIn && fadeInAnimation}
  ${system({
    gap: {
      property: 'gap',
      transform: (value) => `${value}`,
    },
    columnGap: {
      property: 'columnGap',
      transform: (value) => `${value}`,
    },
    rowGap: {
      property: 'rowGap',
      transform: (value) => `${value}`,
    },
  })}
`;

export const Fieldset: FC<FieldsetProps> = ({
  children,
  hiddenLegend,
  id,
  legend,
  legendGridArea,
  legendGridColumnEnd,
  legendGridColumnStart,
  legendFontWeight = '600',
  legendMarginBottom,
  styledLike = 'subheading',
  fadeIn,
  uppercase = true,
  legendFontSize,
  contentVisibility = '',
  ...rest
}) => {
  const theme = useTheme();
  const subheadingMarginBottom = legendMarginBottom || '32px';
  return (
    <FieldsetWrapper
      aria-labelledby={`fieldset-${id}`}
      fadeIn={fadeIn}
      role="group"
      contentVisibility={contentVisibility}
      {...rest}
    >
      <Text
        aria-hidden="true"
        color={styledLike === 'subheading' ? undefined : theme.colors.gray800}
        fontSize={legendFontSize}
        hide={hiddenLegend}
        id={`fieldset-${id}`}
        marginBottom={
          styledLike === 'subheading'
            ? subheadingMarginBottom
            : legendMarginBottom
        }
        fontWeight={legendFontWeight}
        textSize={styledLike === 'subheading' ? undefined : 'xs'}
        variant={styledLike === 'subheading' ? 'subheading' : undefined}
        width={styledLike === 'label' ? '100%' : undefined}
        display="block"
        gridArea={legendGridArea}
        gridColumnEnd={legendGridColumnEnd}
        gridColumnStart={legendGridColumnStart}
        textTransform={uppercase ? 'uppercase' : 'initial'}
      >
        {legend}
      </Text>
      {children}
    </FieldsetWrapper>
  );
};

export default Fieldset;
