export enum ActionTypes {
  FETCH_SUBSCRIPTIONS_FAILURE = 'gofre/subscriptions/FETCH_SUBSCRIPTIONS_FAILURE',
  FETCH_SUBSCRIPTIONS_REQUEST = 'gofre/subscriptions/FETCH_SUBSCRIPTIONS_REQUEST',
  FETCH_SUBSCRIPTIONS_SUCCESS = 'gofre/subscriptions/FETCH_SUBSCRIPTIONS_SUCCESS',
}

export enum StripePaymentStatus {
  STRIPE_STATUS_OPEN = 'open',
  STRIPE_STATUS_PAID = 'paid',
  STRIPE_STATUS_VOID = 'void',
  STRIPE_STATUS_UNCOLLECTIBLE = 'uncollectible',
}

export const PLAN_MONTH = 'P01M';
export const PLAN_YEAR = 'P01Y';

export const TrustedCampaignCodes = [
  'aplifisa_api',
  'contasol_api',
  'aplifisa_migrated_api',
  'contasol_migrated_api',
];

export const RenewableCampaignCodes = [
  'aplifisa_migrated_api',
  'contasol_migrated_api',
];
