import { Formik } from 'formik';

import { DocumentTogglesProvider } from 'modules/Documents/components/DocumentTogglesProvider/DocumentTogglesProvider';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useApiCredentials } from 'modules/Integrations/hooks/useApiCredentials';
import {
  ApiConfiguration,
  ApiConfigurationParams,
  ApiCrendential,
} from 'modules/Integrations/models';
import { useOpenModal } from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import { Box, Button, Text } from 'modules/Ui';

import { apiConfigurationForm } from '../../messages';
import ApiConfigurationConfirmNavigationDialog from './ApiConfigurationConfirmNavigationDialog';
import { ApiConfigurationFormOptions } from './ApiConfigurationFormOptions';
import { ApiConfigurationFormSeries } from './ApiConfigurationFormSeries';
import ApiConfigurationGeneratedCredentials from './ApiConfigurationGeneratedCredentials';
import { normalizeValues } from './normalizeValues';
import { validationSchema } from './validationSchema';

interface Props {
  configuration: ApiConfigurationParams;
  onSubmit: (data: ApiConfiguration) => void;
  formId: string;
}

export const ApiConfigurationForm = ({
  formId,
  configuration,
  onSubmit,
}: Props) => {
  const { config, credentials } = configuration;
  const { t } = useTranslations();
  const openModal = useOpenModal();

  const { mutateDelete, mutateCreate } = useApiCredentials();

  const handleGenerateCredentialsClick = mutateCreate;

  const handleDeleteCredentialClick = (credential: ApiCrendential) => {
    openModal({
      id: credential.id,
      name: credential.name,
      type: ModalTypes.DELETE_CREDENTIAL,
      onSubmit: (id: string) => {
        mutateDelete({ id });
      },
      onCancel: () => {},
    });
  };

  const initialValues = {
    includeOrderReference: true,
    sendAutomatically: false,
    ...config,
  };

  const credentialsExist = credentials?.length;

  return (
    <DocumentTogglesProvider>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, actions) => {
          onSubmit(normalizeValues(values));
          actions.setSubmitting(false);
          actions.resetForm({ values });
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {(formik) => {
          return (
            <Box
              contentVisibility="auto"
              data-testid={`${formId}-test`}
              id={formId}
              noValidate
              onSubmit={formik.handleSubmit}
              tag="form"
            >
              <ApiConfigurationConfirmNavigationDialog />
              <Text
                color="primary500"
                fontSize={14}
                fontWeight="600"
                lineHeight={22}
                marginBottom="8px"
                textTransform="uppercase"
              >
                {t(apiConfigurationForm.sectionCredentials)}
              </Text>
              <Text fontSize={14} lineHeight={18} marginBottom="16px">
                {t(apiConfigurationForm.sectionCredentialsDescription)}
              </Text>
              <Button
                marginBottom={credentialsExist ? '16px' : '24px'}
                onClick={handleGenerateCredentialsClick}
                variant="primary"
              >
                {t(apiConfigurationForm.sectionCredentialsDescriptionButton)}
              </Button>
              {credentialsExist ? (
                <Box marginBottom="24px">
                  <ApiConfigurationGeneratedCredentials
                    credentials={credentials || []}
                    onDelete={handleDeleteCredentialClick}
                  />
                </Box>
              ) : (
                <></>
              )}
              <Box>
                <Text
                  color="gray800"
                  fontSize={14}
                  fontWeight="600"
                  lineHeight={18}
                  textTransform="uppercase"
                >
                  {t(apiConfigurationForm.sectionConfiguration)}
                </Text>
                <Text
                  color="gray600"
                  fontSize={14}
                  lineHeight={20}
                  marginBottom="24px"
                >
                  {t(apiConfigurationForm.sectionConfigurationDescription)}
                </Text>
                <ApiConfigurationFormSeries />
                <ApiConfigurationFormOptions />
              </Box>
            </Box>
          );
        }}
      </Formik>
    </DocumentTogglesProvider>
  );
};
