import { FC, useState } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useOpenModal } from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, OnlyIconButton } from 'modules/Ui';
import { IconEdit, IconTrash } from 'modules/Ui/Icons';

import { PaymentReminder, ToneEnum } from '../../models/paymentReminder';
import { ActiveToggle } from './ActiveToggle';
import messages from './messages';
import { PaymentReminderCreated } from './PaymentReminderCreated';

interface PaymentReminderItemProps {
  paymentReminder: PaymentReminder;
}

export const PaymentReminderItem: FC<PaymentReminderItemProps> = ({
  paymentReminder,
}) => {
  const theme = useTheme();
  const openModal = useOpenModal();

  const { t } = useTranslations();
  const [active, setActive] = useState(!!paymentReminder.active);

  const handleEdit = () => {
    openModal({
      type: ModalTypes.EDIT_PAYMENT_REMINDER,
      tone: paymentReminder.tone || ToneEnum.FRIENDLY,
      when: paymentReminder.days < 0 ? 'before' : 'after',
      subject: paymentReminder.subject,
      body: paymentReminder.body,
      days: paymentReminder.days,
      id: paymentReminder.id,
      active: paymentReminder.active,
    });
  };

  const handleDelete = () => {
    openModal({
      type: ModalTypes.DELETE_PAYMENT_REMINDER,
      id: paymentReminder.id,
    });
  };

  return (
    <Box
      alignItems="center"
      borderBottom={`1px solid ${theme.colors.primary200}`}
      columnGap="12px"
      display="grid"
      fadeIn
      gridTemplateColumns="1fr 48px 40px 40px"
      padding="16px 0"
      tag="li"
    >
      <PaymentReminderCreated days={paymentReminder.days} />
      <ActiveToggle
        active={active}
        paymentReminder={paymentReminder}
        setActive={setActive}
      />
      <Box tag="span">
        <OnlyIconButton
          ariaLabel={t(messages.edit)}
          icon={<IconEdit color="brand500" position="relative" />}
          iconHoverColor={theme.colors.brand500}
          size={40}
          onClick={handleEdit}
        />
      </Box>
      <Box tag="span">
        <OnlyIconButton
          ariaLabel={t(messages.delete)}
          icon={<IconTrash color="brand500" position="relative" />}
          size={40}
          onClick={handleDelete}
        />
      </Box>
    </Box>
  );
};
