import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { AnalyticsEvent } from 'modules/Analytics';
import analyticsService from 'modules/Analytics/services/analyticsService';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import theme from 'modules/Theme';
import styled, { keyframes } from 'modules/Theme/styled-components';
import { Box, Button, Text } from 'modules/Ui';
import { errors } from 'modules/Ui/Errors/messages';
import { IconCross, IconDone, IconAdd, IconInfo } from 'modules/Ui/Icons';

import { removeNotification } from '../../actions';
import {
  Notification as NotificationModel,
  NotificationType,
} from '../../models';
import messages from './messages';

const colorsMapping = {
  [NotificationType.SUCCESS]: {
    border: theme.colors.confirmation,
    bg: theme.colors.confirmationBackground,
  },
  [NotificationType.ERROR]: {
    border: theme.colors.error,
    bg: theme.colors.errorBackground,
  },
  [NotificationType.WARNING]: {
    border: theme.colors.accent400,
    bg: theme.colors.warningBackground,
  },
  [NotificationType.INFO]: {
    border: theme.colors.brand400,
    bg: theme.colors.auxiliary100,
  },
};

const fadeIn = keyframes`
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const NotificationWrapper = styled(Box)`
  animation: ${fadeIn} 0.3s ease-in;
`;

const Notification: React.FC<NotificationModel> = (props) => {
  const { t } = useTranslations();
  const {
    id,
    type,
    title,
    message,
    messageKey,
    ctaTranslationKey,
    onCtaClick,
    hideIcon,
    icon,
    messagePayload,
  } = props;
  const dispatch = useDispatch();

  const handleCloseNotification = useCallback(() => {
    dispatch(removeNotification(id));
  }, [dispatch, id]);

  const handleCtaClick = useCallback(() => {
    onCtaClick?.(props);
    dispatch(removeNotification(id));
  }, [onCtaClick, props, dispatch, id]);

  const colors = colorsMapping[type];

  useEffect(() => {
    analyticsService.track(AnalyticsEvent.NOTIFY, {
      // TODO: investigate when and error with string[] is thrown
      id: messageKey || Array.isArray(message) ? (message as any)[0] : message,
      type,
    });
  }, []);

  const iconComponent = !hideIcon && (icon ?? getIcon(type, title));
  let numberOfChildrens = 2;
  if (iconComponent) {
    numberOfChildrens += 1;
  }
  if (ctaTranslationKey) {
    numberOfChildrens += 1;
  }

  const gridTemplateColumnsDesktop = `${iconComponent ? '24px' : ''} 1fr ${
    ctaTranslationKey ? 'auto' : ''
  } 24px`;

  const gridTemplateColumns = `${iconComponent ? '24px' : ''} 1fr 24px`;

  return (
    <NotificationWrapper
      alignItems="center"
      bg={colors.bg}
      border={`solid 1px ${colors.border}`}
      columnGap="16px"
      data-testid={`alert-${type}`}
      display="grid"
      gridTemplateColumns={{
        _: gridTemplateColumns,
        sm: gridTemplateColumnsDesktop,
      }}
      id={id || messageKey || message}
      marginBottom="8px"
      padding={{ _: '16px', sm: '16px 16px 16px 24px' }}
      role="alert"
      rowGap={{ _: '8px', sm: 'initial' }}
    >
      {iconComponent}

      <Box>
        {title && (
          <Text fontSize={16} lineHeight={28} color="gray800" fontWeight="600">
            {title}
          </Text>
        )}
        <Text
          color="gray800"
          fontSize={{ _: 14, sm: 16 }}
          lineHeight={{ _: 16, sm: 26 }}
        >
          {message && typeof message === 'string'
            ? t({ id: message }, { ...messagePayload })
            : message}
          {!message && t(errors.generic)}
        </Text>
      </Box>

      <Button
        alignSelf="flex-start"
        aria-label={t(messages.close)}
        data-testid="alert-close"
        onClick={handleCloseNotification}
      >
        <IconCross />
      </Button>
      {ctaTranslationKey && (
        <Button
          data-testid={`alert-${type}-cta`}
          gridArea={{
            _: `2 / 1 / 3 / ${numberOfChildrens}`,
            sm: `1 / ${numberOfChildrens - 1} / 2 / ${numberOfChildrens}`,
          }}
          marginRight={{ sm: '16px' }}
          onClick={handleCtaClick}
          type="button"
          variant={type === NotificationType.INFO ? `primary` : `secondary`}
        >
          {t({ id: ctaTranslationKey })}
        </Button>
      )}
    </NotificationWrapper>
  );
};

function getIcon(type: NotificationType, title?: string): JSX.Element | null {
  const alignSelf = title ? 'start' : 'center';

  const iconMapping = {
    [NotificationType.SUCCESS]: (
      <IconDone alignSelf={alignSelf} color={theme.colors.confirmation} />
    ),
    [NotificationType.ERROR]: (
      <IconAdd
        alignSelf={alignSelf}
        color={theme.colors.error}
        transform="rotate(45deg)"
      />
    ),
    [NotificationType.WARNING]: (
      <IconInfo alignSelf={alignSelf} color={theme.colors.accent400} />
    ),
    [NotificationType.INFO]: null,
  };

  return iconMapping[type];
}

export default Notification;
